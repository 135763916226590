"use client";
import { ReactNode } from "react";
import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useSurveillanceMode } from "hooks/useSurveillanceMode";
import { useMaybeOrder } from "services/orders/OrderLoaderContext";

const Content = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  height: 100%;
`;

interface LayoutProps {
  children: ReactNode;
}

/**
 * The main application layout
 * @param props
 * @returns ReactNode
 */
export function AppLayout({ children }: LayoutProps) {
  const order = useMaybeOrder();
  const surveillanceMode = useSurveillanceMode();

  return (
    <>
      <Header />
      {surveillanceMode && order ? (
        <Alert severity="warning">
          You are viewing the customer portal for {order.customer.firstName}{" "}
          {order.customer.lastName}
        </Alert>
      ) : null}
      <Content>{children}</Content>
      <Footer />
    </>
  );
}
