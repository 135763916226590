import { Menu } from "./Menu";
import styled from "@emotion/styled";
import { Logo } from "./Logo";
import Link from "next/link";

const Wrapper = styled.header`
  padding: 0px 24px;
  background-color: #01314a;
  height: 82px;
`;

const LogoWrapper = styled.div`
  svg {
    width: 264px;
    height: 67px;
    @media (max-width: 500px) {
      width: 220px;
      height: 52px;
    }
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export function Header() {
  return (
    <Wrapper>
      <Inner>
        <Link tabIndex={0} href="/">
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>
        <Menu />
      </Inner>
    </Wrapper>
  );
}
