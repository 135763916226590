export function SupportIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1310_273366)">
        <path d="M20.05 5.55523C19.77 5.27523 19.39 5.11523 18.99 5.11523H15.99V2.11523C15.99 1.71523 15.83 1.33523 15.55 1.05523C15.27 0.775234 14.89 0.615234 14.49 0.615234H2.48999C2.08999 0.615234 1.70999 0.775234 1.42999 1.05523C1.14999 1.33523 0.98999 1.71523 0.98999 2.11523V14.1152C0.98999 14.4052 1.15999 14.6652 1.41999 14.7952C1.51999 14.8452 1.62999 14.8652 1.73999 14.8652C1.90999 14.8652 2.06999 14.8052 2.20999 14.6952L5.48999 12.0452V14.8652C5.48999 15.2652 5.64999 15.6452 5.92999 15.9252C6.20999 16.2052 6.58999 16.3652 6.98999 16.3652H15.76L19.26 19.1952C19.4 19.3052 19.56 19.3652 19.73 19.3652C19.84 19.3652 19.95 19.3452 20.05 19.2952C20.31 19.1752 20.48 18.9052 20.48 18.6152V6.61523C20.48 6.21523 20.32 5.83523 20.04 5.55523H20.05ZM4.97999 10.5352L2.48999 12.5452V2.11523H14.49V10.3652H5.44999C5.27999 10.3652 5.10999 10.4252 4.97999 10.5352ZM18.99 17.0452L16.5 15.0352C16.37 14.9252 16.2 14.8652 16.03 14.8652H6.98999V11.8652H14.49C14.89 11.8652 15.27 11.7052 15.55 11.4252C15.83 11.1452 15.99 10.7552 15.99 10.3652V6.61523H18.99V17.0452Z" />
      </g>
      <defs>
        <clipPath id="clip0_1310_273366">
          <rect
            width="19.5"
            height="18.75"
            fill="white"
            transform="translate(0.98999 0.615234)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
