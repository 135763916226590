export const TermsIcon: React.FC = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 13.25C5.25 12.8358 5.58579 12.5 6 12.5H12C12.4142 12.5 12.75 12.8358 12.75 13.25C12.75 13.6642 12.4142 14 12 14H6C5.58579 14 5.25 13.6642 5.25 13.25Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 10.25C5.25 9.83579 5.58579 9.5 6 9.5H12C12.4142 9.5 12.75 9.83579 12.75 10.25C12.75 10.6642 12.4142 11 12 11H6C5.58579 11 5.25 10.6642 5.25 10.25Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.18934 2.43934C1.47064 2.15804 1.85217 2 2.25 2H6C6.41421 2 6.75 2.33579 6.75 2.75C6.75 3.16421 6.41421 3.5 6 3.5L2.25 3.5L2.25 19.25H15.75L15.75 3.5L12 3.5C11.5858 3.5 11.25 3.16421 11.25 2.75C11.25 2.33579 11.5858 2 12 2H15.75C16.1478 2 16.5294 2.15804 16.8107 2.43934C17.092 2.72065 17.25 3.10218 17.25 3.5V19.25C17.25 19.6478 17.092 20.0294 16.8107 20.3107C16.5294 20.592 16.1478 20.75 15.75 20.75H2.25C1.85218 20.75 1.47065 20.592 1.18934 20.3107C0.908035 20.0294 0.75 19.6478 0.75 19.25V3.5C0.75 3.10217 0.908036 2.72064 1.18934 2.43934Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2C8.20435 2 7.44129 2.31607 6.87868 2.87868C6.31607 3.44129 6 4.20435 6 5H12C12 4.20435 11.6839 3.44129 11.1213 2.87868C10.5587 2.31607 9.79565 2 9 2ZM5.81802 1.81802C6.66193 0.974106 7.80653 0.5 9 0.5C10.1935 0.5 11.3381 0.974106 12.182 1.81802C13.0259 2.66193 13.5 3.80653 13.5 5V5.75C13.5 6.16421 13.1642 6.5 12.75 6.5H5.25C4.83579 6.5 4.5 6.16421 4.5 5.75V5C4.5 3.80653 4.97411 2.66193 5.81802 1.81802Z"
    />
  </svg>
);
