export const mcoTransferInfoUrl =
  "https://www.homesafealliance.com/document/6096";
export const movehqPrivacyPolicyUrl = "https://www.movehq.com/privacy-policy";
export const hscTosUrl = "https://www.movehq.com/hsc-tos";
export const factSheetUrl = "https://www.homesafealliance.com/customers";
// TODO: Waiting on HSA for actual urls
export const weightCalculatorUrl =
  "https://www.ustranscom.mil/dp3/weightestimator.cfm";
export const milmovePpmUrl = "https://my.move.mil/";
export const militaryOneSourceUrl =
  "https://www.militaryonesource.mil/resources/podcasts/military-onesource/personally-procured-moves/";
