export function MenuIcon() {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
      <rect
        x="0.199951"
        y="0.399902"
        width="28.8"
        height="2.4"
        rx="1"
        fill="white"
      />
      <rect
        x="0.199951"
        y="10.7998"
        width="28.8"
        height="2.4"
        rx="1"
        fill="white"
      />
      <rect
        x="0.199951"
        y="21.2"
        width="28.8"
        height="2.4"
        rx="1"
        fill="white"
      />
    </svg>
  );
}
