export function CommsIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <path
        style={{ fillRule: "evenodd" }}
        d="M12.5,3.8c-2-0.1-4,0.5-5.6,1.7s-2.7,3-3,5c-0.4,2,0,4,1,5.7C5,16.4,5,16.6,5,16.8l-0.9,3.1L7.2,19
	c0.2-0.1,0.4,0,0.6,0.1c1.7,1,3.8,1.4,5.7,1c2-0.4,3.7-1.5,5-3c1.2-1.6,1.8-3.6,1.7-5.6c-0.1-2-1-3.9-2.4-5.3
	C16.4,4.8,14.5,3.9,12.5,3.8z M7.3,20.6c2,1.1,4.3,1.5,6.5,1c2.3-0.4,4.4-1.7,5.9-3.6c1.5-1.9,2.2-4.2,2-6.6
	c-0.1-2.4-1.2-4.6-2.8-6.3c-1.7-1.7-3.9-2.7-6.3-2.8c-2.4-0.1-4.7,0.6-6.6,2s-3.2,3.5-3.6,5.9c-0.4,2.2-0.1,4.5,1,6.5l-0.8,2.8
	c0,0,0,0,0,0c-0.1,0.3-0.1,0.5,0,0.8C2.7,20.5,2.8,20.8,3,21s0.4,0.3,0.7,0.4c0.3,0.1,0.5,0.1,0.8,0c0,0,0,0,0,0L7.3,20.6z"
      />
      <path d="M12,13.1c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1S11.4,13.1,12,13.1z" />
      <path d="M7.5,13.1c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1S6.4,11.4,6.4,12S6.9,13.1,7.5,13.1z" />
      <path d="M16.5,13.1c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1S15.9,13.1,16.5,13.1z" />
    </svg>
  );
}
