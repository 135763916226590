export function MyOrdersIcon() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.29 5.6251C19.29 5.6251 19.29 5.6251 19.29 5.6151C19.16 5.3851 18.96 5.1951 18.73 5.0651L10.47 0.415098C10.25 0.285098 9.99 0.225098 9.74 0.225098C9.49 0.225098 9.23 0.295098 9.01 0.415098L0.75 5.0551C0.52 5.1851 0.33 5.3751 0.19 5.6051C0.19 5.6051 0.19 5.6151 0.19 5.6251C0.07 5.8451 0 6.0951 0 6.3551V15.6051C0 15.8751 0.07 16.1351 0.21 16.3651C0.34 16.5951 0.54 16.7851 0.77 16.9151L9.02 21.5551C9.24 21.6751 9.49 21.7451 9.74 21.7451C9.74 21.7451 9.74 21.7451 9.75 21.7451C10.01 21.7451 10.26 21.6751 10.48 21.5551L18.73 16.9151C18.96 16.7851 19.16 16.5951 19.29 16.3651C19.42 16.1351 19.5 15.8751 19.5 15.6051V6.3651C19.5 6.1051 19.43 5.8551 19.3 5.6251H19.29ZM9.74 1.7251L17.22 5.9351L14.35 7.5651L6.78 3.3851L9.74 1.7251ZM9.83 10.1251L2.28 5.9251L5.25 4.2551L12.82 8.4351L9.83 10.1351V10.1251ZM1.49 7.2051L9.08 11.4251L9.01 19.8351L1.49 15.6151V7.2051ZM10.5 19.8251L10.57 11.4251L13.58 9.7151V13.2851C13.58 13.6951 13.92 14.0351 14.33 14.0351C14.74 14.0351 15.08 13.6951 15.08 13.2851V8.8651L17.98 7.2151V15.6151L10.49 19.8251H10.5Z"
      />
    </svg>
  );
}
