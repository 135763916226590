export function ClaimsIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <path
        style={{ fillRule: "evenodd" }}
        d="M6.8,12.8c0-0.4,0.3-0.8,0.8-0.8h9c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8h-9C7.1,13.5,6.8,13.2,6.8,12.8z"
      />
      <path
        d="M7.5,4.5c-0.6,0-1.2,0.2-1.6,0.7s-0.7,1-0.7,1.6V9c0,0.4-0.3,0.8-0.8,0.8S3.8,9.4,3.8,9V6.8
	c0-1,0.4-1.9,1.1-2.7S6.5,3,7.5,3h9c1,0,1.9,0.4,2.7,1.1c0.7,0.7,1.1,1.7,1.1,2.7V9c0,0.4-0.3,0.8-0.8,0.8S18.8,9.4,18.8,9V6.8
	c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7H7.5z"
      />
      <path
        d="M3.8,8.3c0.7-0.1,1.5-0.1,2.2,0.2c0.7,0.3,1.3,0.8,1.7,1.4C8,10.5,8.2,11.3,8.2,12v3.8c0,0.4-0.3,0.8-0.8,0.8
	c-0.4,0-0.8-0.3-0.8-0.8V12c0-0.4-0.1-0.9-0.4-1.2s-0.6-0.7-1-0.8C4.9,9.8,4.5,9.7,4.1,9.8c-0.4,0.1-0.8,0.3-1.2,0.6
	c-0.3,0.3-0.5,0.7-0.6,1.2c-0.1,0.4,0,0.9,0.1,1.3c0.2,0.4,0.5,0.8,0.8,1s0.8,0.4,1.3,0.4c0.4,0,0.8,0.3,0.8,0.8v3.8h13.5V15
	c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.9-0.1,1.2-0.4s0.7-0.6,0.8-1c0.2-0.4,0.2-0.9,0.1-1.3c-0.1-0.4-0.3-0.8-0.6-1.2
	c-0.3-0.3-0.7-0.5-1.2-0.6c-0.4-0.1-0.9,0-1.3,0.1c-0.4,0.2-0.8,0.5-1,0.8s-0.4,0.8-0.4,1.2v3.8c0,0.4-0.3,0.8-0.8,0.8
	s-0.8-0.3-0.8-0.8V12c0-0.7,0.2-1.5,0.6-2.1c0.4-0.6,1-1.1,1.7-1.4c0.7-0.3,1.4-0.4,2.2-0.2c0.7,0.1,1.4,0.5,1.9,1
	c0.5,0.5,0.9,1.2,1,1.9c0.1,0.7,0.1,1.5-0.2,2.2c-0.3,0.7-0.8,1.3-1.4,1.7c-0.4,0.3-0.9,0.5-1.3,0.6v3.1c0,0.4-0.2,0.8-0.4,1.1
	s-0.7,0.4-1.1,0.4H5.2c-0.4,0-0.8-0.2-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.1v-3.1c-0.5-0.1-0.9-0.3-1.3-0.6c-0.6-0.4-1.1-1-1.4-1.7
	c-0.3-0.7-0.4-1.4-0.2-2.2c0.1-0.7,0.5-1.4,1-1.9C2.4,8.8,3,8.5,3.8,8.3z"
      />
    </svg>
  );
}
