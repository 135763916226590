export const LogoutIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1310_273371)">
        <path d="M10.5901 4.74023C10.1801 4.74023 9.84009 5.08023 9.84009 5.49023V9.99023C9.84009 9.99023 9.85009 10.0502 9.86009 10.0802C9.86009 10.1302 9.87009 10.1802 9.89009 10.2302C9.91009 10.2802 9.93009 10.3202 9.95009 10.3602C9.97009 10.4002 10.0001 10.4402 10.0301 10.4802C10.0601 10.5202 10.1001 10.5502 10.1501 10.5802C10.1701 10.6002 10.1901 10.6302 10.2201 10.6402L14.1201 12.8902C14.2401 12.9602 14.3701 12.9902 14.4901 12.9902C14.7501 12.9902 15.0001 12.8602 15.1401 12.6102C15.3501 12.2502 15.2201 11.7902 14.8701 11.5902L11.3501 9.56023V5.49023C11.3501 5.08023 11.0101 4.74023 10.6001 4.74023H10.5901Z" />
        <path d="M18.0701 4.9904C17.0801 3.5104 15.6801 2.3604 14.0301 1.6804C12.3801 0.990404 10.5801 0.810404 8.83007 1.1604C7.08007 1.5104 5.49007 2.3604 4.22007 3.6204L2.32007 5.5204V3.5804C2.32007 3.1704 1.98007 2.8304 1.57007 2.8304C1.16007 2.8304 0.820068 3.1704 0.820068 3.5804V7.3304C0.820068 7.4304 0.840068 7.5204 0.880068 7.6204C0.960068 7.8004 1.10007 7.9504 1.29007 8.0304C1.38007 8.0704 1.48007 8.0904 1.58007 8.0904H5.33007C5.74007 8.0904 6.08007 7.7504 6.08007 7.3404C6.08007 6.9304 5.74007 6.5904 5.33007 6.5904H3.39007L5.29007 4.6904C6.34007 3.6404 7.67007 2.9304 9.13007 2.6404C10.5901 2.3504 12.0901 2.5004 13.4601 3.0704C14.8401 3.6404 16.0001 4.5904 16.8301 5.8304C17.6601 7.0704 18.0901 8.5104 18.0901 10.0004C18.0901 11.4904 17.6501 12.9304 16.8301 14.1704C16.0001 15.4104 14.8401 16.3604 13.4601 16.9304C12.0901 17.5004 10.5901 17.6504 9.13007 17.3604C7.67007 17.0704 6.34007 16.3604 5.29007 15.3104C5.00007 15.0204 4.52007 15.0204 4.23007 15.3104C3.94007 15.6004 3.94007 16.0804 4.23007 16.3704C5.49007 17.6304 7.09007 18.4804 8.84007 18.8304C9.42007 18.9504 10.0101 19.0004 10.6001 19.0004C11.7701 19.0004 12.9401 18.7704 14.0401 18.3104C15.6901 17.6304 17.0901 16.4804 18.0801 14.9904C19.0701 13.5004 19.6001 11.7804 19.6001 9.9904C19.6001 8.2004 19.0801 6.4804 18.0801 4.9904H18.0701Z" />
      </g>
      <defs>
        <clipPath id="clip0_1310_273371">
          <rect
            width="18.77"
            height="18"
            fill="white"
            transform="translate(0.820068 0.990234)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
