import { useToggle } from "react-use";
import { theme } from "styles/theme";
import { FormattedMessage } from "react-intl";
import { useIntl } from "services/translation/Intl";
import { useAuth } from "services/auth/AuthContext";
import { useMaybeOrder } from "services/orders/OrderLoaderContext";
import {
  AccountSettingsIcon,
  EntitlementDetailsIcon,
  ManageMoveIcon,
  MyOrdersIcon,
  SupportIcon,
  CommsIcon,
  ClaimsIcon,
  TermsIcon,
} from "./icons";
import MenuIcon from "@mui/icons-material/Menu";
import { List } from "./List";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { LogoutIcon } from "./icons/Logout";
import Drawer from "@mui/material/Drawer";
import { MenuLink } from "./MenuLink";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import { DepotStatus } from "types";
import { useSurveillanceMode } from "hooks/useSurveillanceMode";
import { factSheetUrl, hscTosUrl } from "constant/externalUrls";
import { usePathname } from "next/navigation";

export const MenuIconContainer = styled.div`
  padding: 0;
  cursor: pointer;
`;

const MenuButton = styled.button`
  background: none;
  outline: none;
  border: none;
  display: block;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #0069a0;
  }
  svg {
    display: block;
  }
`;

const CloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  color: ${theme.palette.primary.main};
  position: absolute;
  top: 22px;
  right: 38px;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  width: 400px;
  background: #fff;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

const shouldShowSurveyResults = (status: DepotStatus) => {
  switch (status) {
    case "SURVEY_COMPLETED":
    case "PLANNED":
    case "PACK_CREW_DISPATCHED":
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
    case "IN_TRANSIT":
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "DELIVERY_CREW_DISPATCHED":
    case "DELIVERY_STARTED":
    case "DELIVERY_COMPLETED":
    case "COMPLETED":
      return true;
    default:
      return false;
  }
};

export function Menu() {
  const { isAuthenticated, signOut } = useAuth();
  const [menuIsOpen, setMenuIsOpen] = useToggle(false);
  const { formatMessage } = useIntl();
  const order = useMaybeOrder();
  const surveillanceMode = useSurveillanceMode();

  const isInOnboarding = order?.isInOnboarding || false;

  async function logout() {
    setMenuIsOpen(false);
    await signOut();
  }

  function closeMenu() {
    setMenuIsOpen(false);
  }

  const pathname = usePathname();

  if (!isAuthenticated || pathname === "/no_access") {
    return null;
  }

  return (
    <>
      <MenuIconContainer onClick={() => setMenuIsOpen(true)}>
        <MenuButton aria-label="Open Menu Button">
          <MenuIcon />
        </MenuButton>
      </MenuIconContainer>
      <Drawer
        open={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
        anchor="right"
      >
        <MenuWrapper>
          <CloseButton onClick={() => setMenuIsOpen(false)}>
            <CloseIcon />
          </CloseButton>
          <List aria-label="Navigation Menu">
            {order && isInOnboarding ? (
              <li>
                <MenuLink
                  href="/"
                  onClick={closeMenu}
                  icon={<ManageMoveIcon />}
                >
                  Finish Pre-Move Counseling
                </MenuLink>
              </li>
            ) : null}
            {order && !isInOnboarding ? (
              <li>
                <MenuLink
                  href="/"
                  onClick={closeMenu}
                  icon={<ManageMoveIcon />}
                >
                  Manage Move
                </MenuLink>
              </li>
            ) : null}
            <li>
              <MenuLink
                href="/mymoves"
                onClick={closeMenu}
                icon={<MyOrdersIcon />}
              >
                My Moves
              </MenuLink>
            </li>
            {order && order.hasEntitlementsCounseling ? (
              <li>
                <MenuLink
                  href="/entitlements"
                  onClick={closeMenu}
                  icon={<EntitlementDetailsIcon />}
                  disabled={isInOnboarding}
                >
                  Entitlement Details
                </MenuLink>
              </li>
            ) : null}
            {order &&
              (order?.surveys ?? []).length > 0 &&
              shouldShowSurveyResults(order.status) && (
                <li>
                  <MenuLink
                    href={"/belongings"}
                    onClick={closeMenu}
                    icon={<LibraryAddCheckOutlinedIcon fontSize="small" />}
                  >
                    View Survey Summary
                  </MenuLink>
                </li>
              )}
            {order ? (
              <li>
                <MenuLink
                  href="/inventory?tab=claims"
                  onClick={closeMenu}
                  icon={<ClaimsIcon />}
                  disabled={order.isInOnboarding}
                >
                  Claims
                </MenuLink>
              </li>
            ) : null}

            <li>
              <MenuLink
                href="/communication-history"
                onClick={closeMenu}
                icon={<CommsIcon />}
              >
                Communication History
              </MenuLink>
            </li>

            <li>
              <MenuLink
                href="/support"
                onClick={closeMenu}
                icon={<SupportIcon />}
              >
                Support
              </MenuLink>
            </li>
            <li>
              <MenuLink
                href="/settings"
                onClick={closeMenu}
                icon={<AccountSettingsIcon />}
                disabled={surveillanceMode}
              >
                Account Settings
              </MenuLink>
            </li>
            <li>
              <MenuLink
                href={factSheetUrl}
                onClick={closeMenu}
                icon={<SummarizeOutlinedIcon fontSize="small" />}
                newTab
              >
                Fact Sheet
              </MenuLink>
            </li>
            <li>
              <MenuLink
                href={hscTosUrl}
                onClick={closeMenu}
                icon={<TermsIcon />}
                newTab
              >
                Terms of Service
              </MenuLink>
            </li>
            <li>
              <MenuLink
                aria-label={formatMessage({ id: "ariaLabels.logoutButton" })}
                onClick={logout}
                icon={<LogoutIcon />}
                disabled={surveillanceMode}
              >
                <FormattedMessage id="navigation.logout" />
              </MenuLink>
            </li>
          </List>
        </MenuWrapper>
      </Drawer>
    </>
  );
}
