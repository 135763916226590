import { ReactNode } from "react";
import styled from "@emotion/styled";
import Link from "next/link";
import { Typography, Box } from "@mui/material";
import { colors } from "styles/theme";

interface MenuLinkProps {
  children: ReactNode;
  icon?: ReactNode;
  href?: string;
  onClick(): void;
  disabled?: boolean;
  newTab?: boolean;
}

const MenuLinkWrapper = styled.div<{ disabled: boolean }>`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  svg {
    fill: ${(props) => (props.disabled ? "grey" : "#01314a")};
  }

  a {
    cursor: pointer;
    color: ${(props) => (props.disabled ? "grey" : "#01314a")};
    text-decoration: none;
  }

  a:hover {
    color: ${colors.activeBlue};
    svg {
      fill: ${colors.activeBlue};
    }
  }
`;

const IconWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      height="24px"
      width="24px"
      mr="10px"
      display="flex"
      alignItems="center"
    >
      {children}
    </Box>
  );
};

export const MenuLink: React.FC<MenuLinkProps> = ({
  children,
  href,
  icon,
  onClick,
  disabled = false,
  newTab = false,
}) => {
  return (
    <MenuLinkWrapper disabled={disabled}>
      {href ? (
        <Link
          href={href}
          onClick={onClick}
          {...(newTab ? { target: "_blank" } : {})}
        >
          <IconWrapper>{icon}</IconWrapper>
          <Typography variant="mHeading">{children}</Typography>
        </Link>
      ) : (
        <a onClick={onClick}>
          <IconWrapper>{icon}</IconWrapper>
          <Typography variant="mHeading">{children}</Typography>
        </a>
      )}
    </MenuLinkWrapper>
  );
};
