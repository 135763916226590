export function ManageMoveIcon() {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.45 5.87523C22.45 5.87523 22.45 5.84523 22.45 5.83523L21.14 2.55523C21.03 2.27523 20.84 2.03523 20.59 1.87523C20.34 1.70523 20.05 1.61523 19.75 1.61523H16.51V0.865234C16.51 0.455234 16.17 0.115234 15.76 0.115234H1.5C1.1 0.115234 0.72 0.275234 0.44 0.555234C0.16 0.835234 0 1.21523 0 1.61523V12.1152C0 12.5152 0.16 12.8952 0.44 13.1752C0.72 13.4552 1.1 13.6152 1.5 13.6152H2.73C3.06 14.9052 4.23 15.8652 5.62 15.8652C7.01 15.8652 8.18 14.9052 8.51 13.6152H13.97C14.3 14.9052 15.47 15.8652 16.86 15.8652C18.25 15.8652 19.42 14.9052 19.75 13.6152H20.98C21.38 13.6152 21.76 13.4552 22.04 13.1752C22.32 12.8952 22.48 12.5152 22.48 12.1152V6.11523C22.48 6.03523 22.46 5.95523 22.43 5.87523H22.45ZM19.74 3.11523L20.64 5.36523H16.5V3.11523H19.74ZM15 1.61523V7.61523H1.5V1.61523H15ZM1.5 9.11523H15V10.5452C14.51 10.9452 14.14 11.4852 13.98 12.1152H8.52C8.19 10.8252 7.02 9.86523 5.63 9.86523C4.24 9.86523 3.07 10.8252 2.74 12.1152H1.51V9.11523H1.5ZM5.62 14.3652C4.79 14.3652 4.12 13.6952 4.12 12.8652C4.12 12.0352 4.79 11.3652 5.62 11.3652C6.45 11.3652 7.12 12.0352 7.12 12.8652C7.12 13.6952 6.45 14.3652 5.62 14.3652ZM16.87 14.3652C16.04 14.3652 15.37 13.6952 15.37 12.8652C15.37 12.0352 16.04 11.3652 16.87 11.3652C17.7 11.3652 18.37 12.0352 18.37 12.8652C18.37 13.6952 17.7 14.3652 16.87 14.3652ZM20.99 12.1152H19.76C19.43 10.8252 18.26 9.86523 16.87 9.86523C16.74 9.86523 16.62 9.88523 16.49 9.90523V6.86523H20.99V12.1152Z"
      />
    </svg>
  );
}
