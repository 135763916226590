import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "services";
import { useAuth } from "services/auth/AuthContext";
import { Typography } from "@mui/material";

const Content = styled.div`
  padding: 3rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 5rem;
    display: block;
    text-align: center;
  }
`;

const PadlockImg = styled.img`
  margin-right: 0.5rem;
`;

export function Footer() {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Content>
      <div>
        <PadlockImg
          src="/images/padlock.svg"
          alt={formatMessage({ id: "imageAlt.lock" })}
        />
        <Typography variant="sHeading" mr="10px">
          <FormattedMessage id="app.footer.title" />
        </Typography>
      </div>
      <Typography variant="xsBody">
        <FormattedMessage id="app.footer.message" />
      </Typography>
    </Content>
  );
}
